import { mapGetters } from "vuex";

export default {
    props: [],
    data() {
        return {

        };
    },

    computed: {
        ...mapGetters("documentOperations", [
            "getDocumentExpiryTokenVerified",
            "getRequestNewDocumentLinkStatus",
            "getRequestNewDocumentLinkEmail"
        ])

    },
    methods: {
        async checkTokenValidation(id, token) {
            try {
                await this.$store.dispatch("documentOperations/verifyDocumentExpiryToken", {
                    id,
                    token
                })

                return this.getDocumentExpiryTokenVerified
            }
            catch (err) {
                return false
            }
        },
        async requestNewDocumentLink(params) {
            try {

                await this.$store.dispatch("documentOperations/requestNewDocumentLink", params)
                return {
                    status: this.getRequestNewDocumentLinkStatus,
                    email: this.getRequestNewDocumentLinkEmail
                }
            }
            catch (err) {
                return {
                    status: false,
                    email: null
                }
            }
        }
    }
};


